import { useDispatch, useSelector } from "react-redux"
import React from "react"
import { navigate } from "gatsby"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import MomentUtils from "@date-io/moment"
import TextField from "@material-ui/core/TextField"
import { ButtonStyled } from "./styles/styles"
import styled from "styled-components"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import FormGroup from "@material-ui/core/FormGroup"
import ArrowBack from "@material-ui/icons/ArrowBackIos"

import { addStudentFireStore, fetchAllStudents, fetchStudentsList } from "../redux/firebaseAction"

                            const AddStudentForm = () => {
  const dispatch = useDispatch()
  const { email, studentsList, educatorsList, admin, total_students } = useSelector(state => ({
    ...state.mainReducer,
    ...state.mainReducer.user.user,
  }))

  const [error, setError] = React.useState(false)

  const [cator] = React.useState(email)

  const [form, setForm] = React.useState({
    candidate_no: total_students + 1000000,
    educator_email: admin ? "" : email,
    first_name: "",
    middle_name: "",
    family_name: "",
    name_for_certificate: "",
    gender: "",
    email: "",
    date_of_birth: "",
    tasting_section_score: 0,
    mcqs_section_score: 0,
    tasting_section_file: "",
    mcqs_section_file: "",
    addedBy: "",
    resit: false,
  })

  const handleChange = event => {
    setForm({ ...form, [event.target.name]: event.target.value })
  }
  const handleCheckbox = event => {
    setForm({ ...form, [event.target.name]: event.target.checked })
  }
  const addStudent = () => dispatch(addStudentFireStore(form))
  const FetchStudents = () => dispatch(fetchStudentsList())
  const FetchAllStudents = () => dispatch(fetchAllStudents())

  const handleSave = form => {
    if (
      form.first_name.length !== 0 &&
      form.name_for_certificate.length !== 0 &&
      form.educator_email !== 0 &&
      form.email.length !== 0
    ) {
      FetchStudents()
      FetchAllStudents()
      // setForm({ ...form, [form.candidate_no]: [studentsList.length + 1000000] })
      console.log("nass ins", form)
      addStudent(form)
      navigate("/")
    } else {
      console.log("form fields are required")
      setError(true)
    }
  }
  const handleClose = value => {
    setForm({
      first_name: "",
      middle_name: "",
      family_name: "",
      name_for_certificate: "",
      gender: "",
      email: "",
      date_of_birth: "",
      tasting_section_score: "",
      mcqs_section_score: "",
      resit: false,
    })
  }
  return (
    <>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <table>
          <tbody>
              <CustomTr>
                <td>
                  <ArrowBack onClick={() => navigate("/studentsList" )} />
                </td>
                <td>
                </td>
              </CustomTr>
          </tbody>
        </table>
        <CustomForm>
          <form
            /* style={{ width: 600, margin: "5%" }} */
            noValidate
            autoComplete="off"
          >
            <TextField
              id="standard-basic"
              name="first_name"
              error={error}
              /* placeholder="Location of the event" */
              /* fullWidth */
              required
              value={form.first_name}
              onChange={handleChange}
              label="First Name(s)"
              variant="outlined"
            />
            <TextField
              id="standard-basic"
              name="middle_name"
              error={error}
              /* placeholder="Location of the event" */
              /* fullWidth */
              required
              value={form.middle_name}
              onChange={handleChange}
              label="Middle Name(s)"
              variant="outlined"
            />
            {/* </div> */}
            {/* <div> */}
            <TextField
              id="standard-basic"
              name="family_name"
              /* placeholder="Links to the event,organizer etc" */
              value={form.family_name}
              /* fullWidth */
              onChange={handleChange}
              label="Family Name"
              variant="outlined"
            />
            {/* </div> */}

            {/* <div> */}
            <TextField
              id="standard-basic"
              name="name_for_certificate"
              /* placeholder="Add keywords related to the event(e.g: wine2wine, vinitaly2020..)" */
              value={form.name_for_certificate}
              /* fullWidth */
              onChange={handleChange}
              label="Name for Certificate"
              variant="outlined"
            />
            {/* </div> */}
            {/* <div> */}
            <TextField
              id="standard-basic"
              name="gender"
              /* placeholder="Additional details/notes related to the event" */
              value={form.gender}
              onChange={handleChange}
              /* fullWidth */
              /* multiline={true} */
              label="Gender"
              variant="outlined"
            />
            {/* </div> */}
            {/* <div> */}
            <TextField
              id="standard-basic"
              name="email"
              /* placeholder="Additional details/notes related to the event" */
              value={form.email}
              onChange={handleChange}
              /* fullWidth */
              /* multiline={true} */
              label="Email"
              variant="outlined"
            />
            <TextField
              id="standard-basic"
              name="date_of_birth"
              /* placeholder="Additional details/notes related to the event" */
              value={form.date_of_birth}
              onChange={handleChange}
              /* fullWidth */
              /* multiline={true} */
              label="Date of Birth"
              variant="outlined"
            />
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    name="resit"
                    checked={form.resit}
                    onChange={handleCheckbox}
                    value="checkedA"
                  />
                }
                label="Re-sit?"
              />
            </FormGroup>
            {admin && (
              <>
                <TextField
                  id="standard-basic"
                  type="number"
                  name="tasting_section_score"
                  /* placeholder="Additional details/notes related to the event" */
                  value={form.tasting_section_score}
                  onChange={handleChange}
                  /* fullWidth */
                  /* multiline={true} */
                  label="Tasting Section: Score"
                  variant="outlined"
                />
                <TextField
                  id="standard-basic"
                  type="number"
                  name="mcqs_section_score"
                  /* placeholder="Additional details/notes related to the event" */
                  value={form.mcqs_section_score}
                  onChange={handleChange}
                  /* fullWidth */
                  /* multiline={true} */
                  label="MCQs Section: Score"
                  variant="outlined"
                />
                <TextField
                  id="standard-basic"
                  name="educator_email"
                  placeholder="Email address of the educator NOT student"
                  value={form.educator_email}
                  onChange={handleChange}
                  label="Educator's email address"
                  variant="outlined"
                />
              </>
            )}
            {/* </div> */}
            <div style={{ textAlign: "right" }}>
              <ButtonStyled
                text="black"
                bg="white"
                onClick={() => handleClose()}
              >
                Cancel
              </ButtonStyled>
              <ButtonStyled onClick={() => handleSave(form)}>Save</ButtonStyled>
            </div>
          </form>
        </CustomForm>
      </MuiPickersUtilsProvider>
    </>
  )
}

export default AddStudentForm

//styled-components
const CustomForm = styled.div`
  .MuiFormGroup-root {
    padding: 0 10px;
  }
  .MuiTextField-root {
    width: 100%;
    margin: 10px;
  }
  form {
    // width:50%
    margin: 0 25%;
  }
  form label,
  input,
  h2,
  label,
  span,
  input,
  textarea {
    font-size: 16px;
    font-family: "GT-Walsheim-Regular", sans-serif;
  }
  .MuiDialog-paper {
    min-width: 20%;
  }
  // span {
  //   font-size: 12px;
  // }
  svg {
    width: 0.6em;
  }
`
const CustomTr = styled.tr`
  background-color: black;
  color: white;
`
